<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Mashina o'zgartirish</h3>
            </div>
            <div class="card-button"></div>
          </div>
          <div class="card-body">
            <div class="col">
              <v-autocomplete
                v-model="getTruckDetails.driver"
                :items="driverList"
                label="Haydovchi"
                outlined
                dense
                item-text="full__name"
                item-value="id"
              ></v-autocomplete>
            </div>

            <div class="col">
              <v-autocomplete
                v-model="getTruckDetails.truck_type_description"
                label="Mashina modeli"
                :items="allTruckTypes"
                outlined
                item-text="truck__name"
                item-value="id"
                dense
              ></v-autocomplete>
            </div>
            <div class="col">
              <v-autocomplete
                v-model="getTruckDetails.main_assets"
                label="Asosiy vositalar "
                :items="listMainAsset"
                outlined
                item-text="mainAssets_name"
                item-value="id"
                dense
              ></v-autocomplete>
            </div>
            <div class="d-flex flex-wrap">
              <div class="col-4">
                <label>Region raqami</label>
                <v-text-field
                  outlined
                  dense
                  v-model="getTruckDetails.reg_number_region"
                >
                </v-text-field>
              </div>

              <div class="col-4">
                <label>Mashina raqami</label>
                <v-text-field
                  outlined
                  dense
                  v-model="getTruckDetails.reg_number"
                >
                </v-text-field>
              </div>

              <div class="col-4">
                <label>Kuzov raqami</label>
                <v-text-field
                  outlined
                  dense
                  v-model="getTruckDetails.kuzov_number"
                >
                </v-text-field>
              </div>

              <div class="col-4">
                <label>Kuzov turi</label>
                <v-text-field
                  outlined
                  dense
                  v-model="getTruckDetails.kuzov_type"
                >
                </v-text-field>
              </div>
              <div class="col-4">
                <label>Dvigatel raqami</label>
                <v-text-field
                  outlined
                  dense
                  v-model="getTruckDetails.dvigatel_number"
                >
                </v-text-field>
              </div>
              <div class="col-4">
                <label>Sotib olingan sana</label>

                <v-dialog
                  ref="dialog"
                  :return-value.sync="date5"
                  v-model="modal"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date5"
                      prepend-icon="event"
                      outlined
                      v-bind="attrs"
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date5" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="modal = false"
                      >Bekor qilish</v-btn
                    >
                    <v-btn
                      small
                      color="success"
                      @click="$refs.dialog.save(date5)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
            <div class="col">
              <v-btn color="success" @click="submit">Yuborish</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      date5: '',
      modal: false,
      listMainAsset: []
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllDrivers')
    this.$store.dispatch('getTruckLists')
    this.$store.dispatch('getAllTruckTypes')
    this.$store.dispatch('notLinkedTotruck')
    this.$store.dispatch('truckListall', this.$route.params.id)
  },
  watch: {
    getTruckDetails: function (val) {
      if (val !== undefined) {
        this.date5 = val.bought_date
        if (this.listMainAsset.length == 0) {
          this.listMainAsset.push(val.main_assets_description)
        } else if (
          this.listMainAsset.find((x) => x.id == val.main_assets) == undefined
        ) {
          this.listMainAsset.push(val.main_assets_description)
        }
      }
    },
    getMainAssetsList: function (val) {
      if (val !== undefined) {
        this.listMainAsset = []
        this.listMainAsset = [...val]
      }
    },
    listMainAsset: function (val) {
      if (
        val.find((x) => x.id == this.getTruckDetails.main_assets) == undefined
      ) {
        val.push(this.getTruckDetails.main_assets_description)
      }
      val.forEach((element) => {
        if (element !== undefined) {
          element.mainAssets_name =
            'Inventar nomeri' +
            ' ' +
            element.inventar_number +
            ', ' +
            'Nomi: ' +
            element.name +
            ', Summasi: ' +
            element.total_amount.toLocaleString('zh-ZH')
        }
      })
    }
  },
  computed: {
    getTruckDetails() {
      const data = this.$store.state.requests.truckListallstore
      console.log('data', data)
      if (
        data.truck_type_description !== undefined &&
        data.truck_type_description !== null
      ) {
        if (data.truck_type_description.id)
          data.truck_type_description = data.truck_type_description.id
      }
      return data
    },
    driverList() {
      return this.$store.state.requests.allDrivers
    },
    allTruckTypes() {
      const data = this.$store.state.requests.allTruckTypes
      data.forEach((x) => {
        x.truck__name = x.brand.name + ' ' + x.model
      })
      return data
    },
    getMainAssetsList() {
      const data = this.$store.state.requests.notLinkedTotruck
      data.forEach((element) => {
        element.mainAssets_name =
          'Inventar nomeri' +
          ' ' +
          element.inventar_number +
          ', ' +
          'Nomi: ' +
          element.name +
          ', Summasi: ' +
          element.total_amount
      })
      return data
    }
  },
  methods: {
    submit() {
      const id = {
        id: this.$route.params.id
      }
      const data = {
        kuzov_type: this.getTruckDetails.kuzov_type,
        driver: this.getTruckDetails.driver,
        truck_type: this.getTruckDetails.truck_type_description,
        reg_number_region: this.getTruckDetails.reg_number_region,
        reg_number: this.getTruckDetails.reg_number,
        kuzov_number: this.getTruckDetails.kuzov_number,
        dvigatel_number: this.getTruckDetails.dvigatel_number,
        main_assets: this.getTruckDetails.main_assets,
        bought_date: this.date5
      }
      this.$store.dispatch('updateTruck', { id, data })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Transport' },
      { title: "Mashina o'zgartirish" }
    ])
  }
}
</script>

<style scoped>
.v-input /deep/ .v-input__prepend-outer {
  display: none !important;
}
</style>
